.checkout {
  position: relative;
  height: var(--content-height);
  width: 100vw;
  padding: 48px 84px 84px;
  display: flex;
  background-color: var(--background);

  .col {
    padding: 0px 24px;
    flex: 1;
    display: flex;
    flex-direction: column;

    .col-header {
      margin-bottom: 16px;
      color: var(--dark-primary);
      font-size: 36px;
      line-height: 60px;
      font-family: Pockota;
      font-weight: 600;
    }
  }

  .terms-and-conditions-disclaimer {
    margin-top: 24px;
    text-align: center;
    font-size: 12px;
    color: var(--medium-gray);

    .terms-and-conditions-link {
      color: var(--orange);
      font-weight: 600;
    }
  }

  .checkout-wrapper {
    max-width: 480px;
  }

  .materials-reminder {
    margin-top: 16px;
    text-align: center;
    font-size: 12px;
    color: var(--medium-gray);
  }
  .checkbox-row {
    padding: 10px 0px;
    margin-bottom: 16px;
    margin-top: 16px;
    color: var(--medium-gray);
    width: 100%;
  }

  .link {
    color: var(--medium-gray);
    margin-top: 8px;
    display: block;
    text-decoration: underline;
  }
}

@media screen and (max-width: 568px) {
  .checkout {
    height: auto;
    min-height: var(--content-height);
    padding: 0px 20px;
    display: block;

    .col {
      padding: 0px;
      padding-top: 36px;

      .col-header {
        width: 100%;
        margin: 0px;
        margin-bottom: 24px;
        font-size: 40px;
        line-height: 40px;
        text-align: center;
      }

      .enrollment-item {
        margin: 0px;
        padding: 12px;

        &:not(:last-child) {
          margin-bottom: 24px;
          padding-bottom: 24px;
        }

        .bottom-row {
          flex-direction: column;

          .total {
            margin-bottom: 16px;
          }
        }
      }
    }

    .checkbox-row {
      padding: 0px;
      margin-bottom: 0px;
      margin-top: 0px;
      color: var(--medium-gray);
      width: 100%;
    }

    .terms-and-conditions-disclaimer {
      margin-top: -16px;
      padding-bottom: 16px;
    }

    .materials-reminder {
      padding-bottom: 36px;
    }
  }
}
